var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticStyle: { display: "flex" } },
      [
        _c("Input", {
          staticStyle: { "margin-right": "10px" },
          attrs: {
            readonly: "",
            size: _vm.size,
            disabled: _vm.disabled,
            prefix: _vm.prefix,
            suffix: _vm.suffix,
            placeholder: _vm.placeholder,
            clearable: _vm.clearable,
          },
          on: { "on-clear": _vm.clearSelect },
          model: {
            value: _vm.departmentTitle,
            callback: function ($$v) {
              _vm.departmentTitle = $$v
            },
            expression: "departmentTitle",
          },
        }),
        _c(
          "Poptip",
          {
            attrs: {
              transfer: "",
              trigger: "click",
              placement: "right",
              title: "选择部门",
              width: "250",
            },
          },
          [
            _c(
              "Button",
              {
                attrs: {
                  size: _vm.size,
                  disabled: _vm.disabled,
                  icon: _vm.icon,
                  type: _vm.type,
                  shape: _vm.shape,
                  ghost: _vm.ghost,
                },
              },
              [_vm._v(_vm._s(_vm.text))]
            ),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("Input", {
                  attrs: {
                    suffix: "ios-search",
                    placeholder: "输入部门名搜索",
                    clearable: "",
                  },
                  on: { "on-change": _vm.searchDep },
                  model: {
                    value: _vm.searchKey,
                    callback: function ($$v) {
                      _vm.searchKey = $$v
                    },
                    expression: "searchKey",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "dep-tree-bar" },
                  [
                    _c("Tree", {
                      attrs: {
                        data: _vm.dataDep,
                        "load-data": _vm.loadData,
                        multiple: _vm.multiple,
                      },
                      on: { "on-select-change": _vm.selectTree },
                    }),
                    _vm.depLoading
                      ? _c("Spin", { attrs: { size: "large", fix: "" } })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }